
const imagesPrefix =
  'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections';

export default [
	{
		title: 'Что мы умеем?',
		description: 'И делаем это очень хорошо',
		items: [
			{
				image: `http://eagcorona.com/Assets/Images/iletisim-ust.jpg`,
				name: 'Собираем и структурируем любые открытые данные',
				description: 'У нас есть различные инструменты, позволяющие находить, извлекать и структурировать информацию из открытых источников. Реализуем как разовый парсинг по запросу, так и регулярный мониторинг данных',
				icon: 'settings_ethernet'
			},
			{
				image: `${imagesPrefix}/features.jpg`,
				name: 'Делаем аналитические исследования на основе открытых данных',
				description: 'Данные должны приносить пользу. Извлекаем факты из неструктурированных данных, проводим анализ рынка и конкурентов, готовим тематические аналитические материалы, строим прогнозные модели',
				icon: 'donut_small'
			},
			{
				image: `${imagesPrefix}/pricing.jpg`,
				name: 'Создаем модели рисков на основе открытых данных',
				description: 'Вокруг бизнеса происходит множество событий, которые могут быть негативными для бизнеса. Мы знаем как построить систему мониторинга внешней среды бизнеса и своевременно выявлять риски',
				icon: 'electric_bolt'
			},
			{
				image: `${imagesPrefix}/faq.jpg`,
				name: 'Формируем досье на персону из открытых источников',
				description: 'Люди - главный ресурс, но и источник рисков для бизнеса. Мы разработали систему мониторинга физических лиц для бизнеса -  myCheck. Даем уверенность в благонадежности кандидатов, сотрудников и бизнес-партнеров',
				icon: 'person'
			}
		]
	},
	{
		title: 'Наши проекты',
		description: 'Часть проектов и исследований, о которых мы можем говорить, не нарушая NDA',
		items: [
			{
				image: `${imagesPrefix}/testimonials.jpg`,
				name: 'Сервис проверки физических лиц для бизнеса myCheck',
				description: 'Разработали сервис, который собирает открытые данные о физических лицах и формирует отчеты для HR и Службы безопасности',
				icon: 'person_search',
				action: {
					type: 'external',
					route: 'https://my-check.ru',
					label: "На сайт сервиса"
				}			
			},
			{
				image: `http://eagcorona.com/Assets/Images/iletisim-ust.jpg`,
				name: 'Выявление конфликта интересов через связи между профилями VK',
				description: 'Выявили сотрудников, которые имеют связи с конкурентами, на основании сбора данных о профилях VK. Использовались сведения об указанных в VK родственниках, "друзьях друзей", фактах обучения в одной школе/ВУЗе в одно время и т.д. Для каждого типа связи строилась модель весов, с учетом значимости и достоверности связи',
				icon: 'hub'
			},
			{
				image: `${imagesPrefix}/features.jpg`,
				name: 'Формирование базы неполитических протестов',
				description: 'Выполнили сбор 2 млн. новостных сообщений о неполитических протестах (например, в сфере ЖКХ, экологии или из-за запрета праворульных автомобилей). Структурировали сведения об участниках, предмете и событиях, связанных с конфликтом. Подготовленную базу данных передали заказчику для дальнейшего исследования, как неполитические протесты превращаются в политические',
				icon: 'thumb_down_off_alt'
			},
			{
				image: `${imagesPrefix}/faq.jpg`,
				name: 'Моделирование динамики новостных сообщений',
				description: 'Мы исследовали как происходит распространение негативных новостей о персоне или организации и как можно "гасить" это распространение. Были построены модели и показано, что опровержения и любые комментарии только усиливают распространение негатива. Самой эффективной тактикой оказалось переключение людей, репостящих негатив на другой контент (возможно, еще более шокирующий), но не относящийся к защищаемой персоне или организации',
				icon: 'line_axis'
			},
			{
				image: `${imagesPrefix}/blogs.jpg`,
				name: 'Отношения звездных пар',
				description: 'В рамках одного из исследований собирали новостные сообщения с сервиса Леди@mail. Извлекали из текстов новостей сведения о персонах и отношениях между персонами (кто с кем начал встречаться, кто с кем расстался и т.д.). Находили самых любвеобильных и самых одиноких звезд :)',
				icon: 'social_distance'
			},
			{
				image: `${imagesPrefix}/testimonials.jpg`,
				name: 'Прогноз продаж новых автомобилей',
				description: 'Реализовали модель прогнозирования продаж новых автомобилей в регионе на основе 20 параметров (как открытых, так и закрытых отраслевых). Обеспечили регулярный сбор данных и автоматический рассчет прогноза',
				icon: 'bar_chart'
			}
		]
	}
];
