// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import MKSocialButton from 'components/MKSocialButton';

// Material Kit 2 React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import DefaultFooter from 'examples/Footers/DefaultFooter';
import FilledInfoCard from 'examples/Cards/InfoCards/FilledInfoCard';

// Presentation page sections
import Counters from 'pages/Presentation/sections/Counters';
import Information from 'pages/Presentation/sections/Information';
import DesignBlocks from 'pages/Presentation/sections/DesignBlocks';
import Pages from 'pages/Presentation/sections/Pages';
import Testimonials from 'pages/Presentation/sections/Testimonials';
import Download from 'pages/Presentation/sections/Download';
import Contact from 'pages/Presentation/sections/Contact';

// Presentation page components
import BuiltByDevelopers from 'pages/Presentation/components/BuiltByDevelopers';

// Routes
import routes from 'routes';
import footerRoutes from 'footer.routes';

// Images
import bgImage from 'assets/images/bg/003.jpg';

function IndexPage() {
	return (
		<>
			<DefaultNavbar
				routes={routes}
				action={{
					type: 'external',
					route: 'https://id.my-check.ru',
					label: 'Вход',
					color: 'info'
				}}
				sticky
				brand={'Исследовательская группа "Тегия"'}
			/>

			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: `url(${bgImage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'top',
					display: 'grid',
					placeItems: 'center'
				}}
			>

        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
				textAlign: 'center',
				backgroundColor: '#262626', // '#262626',
                [breakpoints.down('md')]: {
                  fontSize: size['3xl']
                }
              })}
            >
              Создаем аналитику на открытых данных{' '}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
			  sx={{backgroundColor: '#262626', padding: '10px'}}
            >
              Мы знаем, как превратить открытые данные в полезный ресурс и инструмент развития Вашей компании 
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl
        }}
      >
        <Counters />

        {/*<Information />*/}

        <DesignBlocks />

		<Contact />



      </Card>
      {/*<MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
	</MKBox>*/}
    </>
  );
}

export default IndexPage;
