
// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Material Kit 2 React components
import MKBox from 'components/MKBox';

// Material Kit 2 React examples
import DefaultCounterCard from 'examples/Cards/CounterCards/DefaultCounterCard';

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: 'auto' }}>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              count={30}
              suffix="+"
              title="Выполненных проектов"
              description="Парсинг и агрегация открытых данных, проекты по аналитике, модели рисков"
            />
          </Grid>
          <Grid item xs={12} md={6} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'block' }, mx: 0 }} />
            <DefaultCounterCard
              count={27}
              suffix=" лет"
              title="Наш опыт"
              description="Мы посчитали суммарный опыт нашей команды по парсингу открытых данных, аналитике и управлению рисками"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
