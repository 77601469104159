

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKBadge from 'components/MKBadge';
import MKTypography from 'components/MKTypography';

// Presentation page components
import ExampleCard from 'pages/Presentation/components/ExampleCard';
import FilledInfoCard from 'examples/Cards/InfoCards/FilledInfoCard';
import TransparentBlogCard from 'examples/Cards/BlogCards/TransparentBlogCard';
import CaseCard from 'components/Cards/CaseCard';
import CaseCard2 from 'components/Cards/CaseCard2';
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard';

// Data
import data from 'pages/Presentation/sections/data/designBlocksData';

function DesignBlocks() {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ icon, image, name, description, count, action, route, pro }) => (
            <Grid item xs={12} md={6} sx={{ mb: 2 }} key={name}>
             
                {/*<ExampleCard image={image} name={name} count={count} pro={pro} />*/}

				<CaseCard2
                color="info"
                icon={icon}
                title={name}
                description={description}
				action={action}
                /*action={{
                  type: 'internal',
                  route: route,
                  label: "Подробнее"
                }}
				*/
			/>

			{/*<CaseCard
              image={image}
              title={name}
              description="Finding temporary housing for your dog should be as easy as renting an Airbnb. That’s the idea behind Rover ..."
              action={{
                type: 'internal',
                route: route,
                color: 'info',
                label: 'Подробнее'
              }}
            />	*/}	

					

            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      {/*<Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: 'center', my: 6, mx: 'auto', px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Infinite combinations"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Huge collection of sections
          </MKTypography>
          <MKTypography variant="body1" color="text">
            We have created multiple options for you to put together and customise into pixel
            perfect pages.
          </MKTypography>
        </Grid>
  </Container>*/}
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
